import React, { useState, useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import removeUnderscoreKeys from '../utilities/RemoveDataUnderscoreDuplicates';
import TransformImagesData from '../utilities/TransformImagesData';
import ImageWithFadeIn from '../utilities/ImageWithFadeIn';

const HeroBlock = ({ block }) => {
    const attributes = block.attributes.data;
    const globalSettings = null;
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [loading, setLoading] = useState(true);
    const [blockSettings, setBlockSettings] = useState(null);
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        const globalSettings = window.globalSettings;
        setBlockSettings(globalSettings.data.globalSettings.globals.settings.blocksettings)
        setLoading(false)
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    let MaxLogoWidth = attributes.hero_logo_max_width;
    let LogoHeight = attributes.hero_logo_height;
    let DesktopMaxHeight = attributes.hero_max_desktop_height;

    // Function to render buttons dynamically based on the count in hero_buttons
    const renderButtons = () => {
        let buttons = [];
        for (let i = 0; i < attributes.hero_buttons; i++) {
            const buttonKey = `hero_buttons_${i}_button`;
            const buttonData = attributes[buttonKey];
            const secondaryKey = `hero_buttons_${i}_secondary`;
            const secondaryStyle = attributes[secondaryKey];
            if (buttonData) {
                buttons.push(
                    <a key={i} className={secondaryStyle == "1" ? 'btn secondary' : 'btn'} href={buttonData.url} target={buttonData.target || "_self"} style={{ margin: '5px' }}>
                        {buttonData.title}
                    </a>
                );
            }
        }
        return buttons;
    };

    return (
        <div 
            className={`hero ${attributes.hero_type} ${attributes.hero_dark_ui == "1" ? 'dark' : ''} ${windowWidth > 1250 && DesktopMaxHeight ? 'max-height-set' : ''}`}
        >
          {!loading &&
            <div className="hero__inner">
                { attributes.hero_type == "two_column" &&
                    <div className="grid-wrapper" style={{ maxHeight: windowWidth > 1250 ? `${DesktopMaxHeight}px` : 'auto', minHeight: windowWidth > 1250 ? `${DesktopMaxHeight}px` : 'auto'  }}>
                        {blockSettings &&
                            blockSettings.desktopTwoColumnImageSepatator &&
                            <img src={blockSettings.desktopTwoColumnImageSepatator.node.sourceUrl}  alt=" " className="anchor-image-middle" onError={() => console.error("Error loading image:", blockSettings.desktopTwoColumnImageSepatator)}/>
                        }
                        <div className="grid lap-one-half">
                            <div className="content">
                                { attributes.hero_desktop_background_art &&
                                    <div className="background-art">
                                        <ImageWithFadeIn src={attributes.hero_desktop_background_art.url} alt={attributes.hero_desktop_background_art.alt} />
                                    </div>
                                }
                                {attributes.hero_dark_ui != "1" &&
                                  attributes.hero_logo &&
                                  <div className="hero__logo" style={{maxWidth: MaxLogoWidth+"px", height: LogoHeight+"px"}}>
                                    <ImageWithFadeIn src={attributes.hero_logo.url} alt={attributes.hero_logo.alt} />
                                  </div>
                                }
                                {attributes.hero_dark_ui == "1" &&
                                  attributes.hero_logo &&
                                    <div className="hero__logo" style={{maxWidth: MaxLogoWidth+"px", height: LogoHeight+"px"}}>
                                      <ImageWithFadeIn src={attributes.hero_logo_dark.url} alt={attributes.hero_logo_dark.alt} />
                                    </div>
                                }

                                {attributes.hero_header && React.createElement(
                                    attributes.hero_header_seo || 'div',
                                    { className: 'hero-header' },
                                    attributes.hero_header
                                )}

                                {attributes.hero_content && <div className="hero-body">{attributes.hero_content}</div>}


                                <div className="buttons-wrapper">
                                    {renderButtons()}
                                </div>
                            </div>
                        </div>
                        <div className="grid lap-one-half">
                            { attributes.hero_images &&
                              <div className="hero--images-container">
                                <ImageWithFadeIn src={attributes.hero_images[0].url} alt={attributes.hero_images[0].alt} />
                              </div>
                            }
                        </div>
                    </div>
                }
                { attributes.hero_type == "one_column" &&
                    <div className="content-wrapper">
                        {blockSettings &&
                          blockSettings.desktopHeroSingleColumnImageSeparator &&
                          <img src={blockSettings.desktopHeroSingleColumnImageSeparator.node.sourceUrl}  alt=" " className="anchor-image-bottom"/>
                        }
                        <div className="content">
                            {attributes.hero_header && React.createElement(
                                attributes.hero_header_seo || 'div',
                                { className: 'hero-header', dangerouslySetInnerHTML: { __html: attributes.hero_header } }
                            )}

                            {attributes.hero_content && <div className="hero-body">{attributes.hero_content}</div>}


                            <div className="buttons-wrapper">
                                {renderButtons()}
                            </div>
                        </div>
                    </div>
                }
            </div>
          }
        </div>
    );
}

export default HeroBlock;
