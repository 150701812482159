import React, {useState, useEffect} from 'react';
import { useQuery, gql } from '@apollo/client';
import TransformContentData from '../utilities/TransformContentData';
import TransformImagesData from '../utilities/TransformImagesData';
import RenderRichContent from '../utilities/RenderRichContent';
import GetSlidesForSlider from '../utilities/GetSlidesForSlider'
import ImageWithFadeIn from '../utilities/ImageWithFadeIn';


// const attributes = block.attributes.data;
// const globalSettings = null;
// const [windowWidth, setWindowWidth] = useState(window.innerWidth);
// const [loading, setLoading] = useState(true);
// const [blockSettings, setBlockSettings] = useState(null);
// useEffect(() => {
//     const handleResize = () => setWindowWidth(window.innerWidth);
//     const globalSettings = window.globalSettings;
//     setBlockSettings(globalSettings.data.globalSettings.globals.settings.blocksettings)
//     setLoading(false)
//     window.addEventListener('resize', handleResize);

//     // Clean up the event listener on component unmount
//     return () => window.removeEventListener('resize', handleResize);
// }, []);

const ImageAndTextBlock = ({ block }) => {
    const attributes = block.attributes.data;
    let Images = TransformImagesData(attributes.image_and_text_block_images);
    let contentRepeater = TransformContentData(attributes);
    const [loading, setLoading] = useState(true);
    const [blockSettings, setBlockSettings] = useState(null);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        touchThreshold: 10,
        slidesToShow: 1.05,
        slidesToScroll: 1
    };

    useEffect(() => {
      const globalSettings = window.globalSettings;
      setBlockSettings(globalSettings.data.globalSettings.globals.settings.blocksettings)
      setLoading(false)
  }, []);

    const baseClass = "grid-wrapper";
    const className = `${baseClass} ${attributes.image_and_text_block_reverse_grid === "1" ? 'grid-wrapper--reverse' : ''}`;
    
    return (
        <div className="image-and-text-block">
            <div className={className}>
                <div className="grid left">
                    <div className="inner">
                        { attributes.image_and_text_block_images.length > 1 ? 
                            <div className="slider-wrapper">
                                <GetSlidesForSlider data={attributes.image_and_text_block_images} show={1} /></div>
                            :
                            <div>
                              <ImageWithFadeIn src={attributes.image_and_text_block_images[0].url} alt={attributes.image_and_text_block_images[0].alt} />
                            </div>
                        }
                        {blockSettings &&
                            blockSettings.cardArt &&
                            <img src={blockSettings.cardArt.node.sourceUrl}  alt=" " className="anchor-image-bottom"/>
                        }
                    </div>
                </div>
                <div className="grid right">
                    {contentRepeater.length > 0 &&
                        <div className="content">
                            {contentRepeater.map((block, index) => (
                                <RenderRichContent key={index} block={block} />
                            ))}
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default ImageAndTextBlock