import React from 'react';
import { gql, useQuery } from '@apollo/client';

const QUERY = gql`
query GetEvents($slug: String!) {
  eventBy(slug: $slug) {
    eventSettings {
      additionalEventSettings {
        eventRegistrationUrl
        eventRegistrationTextOverride
        multipleDays {
          date
          eventTimes {
            endTime
            startTime
          }
        }
        isMultipleDays
      }
    }
  }
}`;

const formatDate = (dateStr) => {
  // Extract the date part (YYYY-MM-DD) from the ISO string
  const [year, month, day] = dateStr.split('T')[0].split('-');

  // Convert the extracted year, month, and day to a proper formatted date
  const date = new Date(Date.UTC(year, month - 1, day));

  // Format the date using UTC values to prevent any timezone offset issues
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return date.toLocaleDateString('en-US', { ...options, timeZone: 'UTC' });
};

const formatTimeRange = (startTime, endTime) => {
  const formatTime = (timeStr) => {
    if (!timeStr) return '';
    const [hour, minuteAndMeridiem] = timeStr.split(':');
    return `${hour}:${minuteAndMeridiem.toUpperCase()}`;
  };

  const start = formatTime(startTime);
  const end = endTime ? formatTime(endTime) : '';
  return end ? `${start}–${end}` : start;
};


const GetEventACFSettings = ({ slug }) => {
  console.log(slug)
  const { loading, error, data } = useQuery(QUERY, { variables: { slug } });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const { eventRegistrationUrl, eventRegistrationTextOverride, multipleDays} = data.eventBy.eventSettings.additionalEventSettings;
  let upcomingEvents = [];
  const today = new Date().toISOString().split('T')[0]; // Current date in YYYY-MM-DD format

  // Filter upcoming events
  if (multipleDays) {
    upcomingEvents = multipleDays
      .filter(day => day.date >= today) // Exclude past dates
      .map(day => ({
        date: formatDate(day.date),
        times: day.eventTimes && day.eventTimes.length > 0
          ? day.eventTimes.map(time => formatTimeRange(time.startTime, time.endTime)).join(', ')
          : 'TBD' // Set "TBD" if there are no event times
      }));
  }  

  return (
    <>
      {upcomingEvents.length > 0 && (
        <div className="upcoming-events">
          <h4>Upcoming Dates</h4>
          {upcomingEvents.map((event, index) => (
            <div key={index} className="event-item">
              <div className="event-date">{event.date}</div>
              <div className="event-times">{event.times}</div>
            </div>
          ))}
        </div>
      )}
      {eventRegistrationUrl && (
        <div className="registration-button">
          <a href={eventRegistrationUrl} className="button btn">
            {eventRegistrationTextOverride || 'Register'}
          </a>
        </div>
      )}
    </>
  );
};

export default GetEventACFSettings;