import React, { useState, useEffect, useRef } from 'react';
import removeUnderscoreKeys from '../utilities/RemoveDataUnderscoreDuplicates'

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

function extractDateDetails(dateString) {
    if (!dateString) return null;
    const date = new Date(dateString);
    const dayOfMonth = date.getDate();
    const month = date.getMonth();
    const dayOfWeekNumber = date.getDay();

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    
    const monthName = monthNames[month];
    const dayName = dayNames[dayOfWeekNumber];

    return {
        dayOfMonth: dayOfMonth,
        month: monthName,
        dayOfWeek: dayName
    };
}

function formatDate(dateString) {
    // Create an array of abbreviated month names
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
    // Parse the date string
    const dateParts = dateString.split('/');
    const month = parseInt(dateParts[0], 10);
    const day = parseInt(dateParts[1], 10);
    const year = parseInt(dateParts[2], 10);
    
    // Create a Date object
    const date = new Date(year, month - 1, day);
    
    // Return an object with the abbreviated month and the day
    return {
        month: monthNames[date.getMonth()],
        monthDay: date.getDate()
    };
}


const CategoryFilter = ({ categories, selectedCategories, onChange, view }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleSelection = (category) => {
        // If "All" is selected, reset the selected categories to ["All"]
        if (category === "All") {
            onChange(["All"]);
        } else {
            onChange([category]);
        }
        setIsOpen(false);
    };

    return (
        <div className="category-filter">
            <div className="category-filter-inner" onClick={toggleDropdown}>
                <div className="selected-category">
                    {selectedCategories[0]}
                </div>
                {isOpen && (
                    <ul className="category-list">
                        {/* Add an option to select "All" */}
                        <li key="All" onClick={() => handleSelection("All")}>
                            All
                        </li>
                        {categories.map(category => (
                            <li key={category.value} onClick={() => handleSelection(category.value)}>
                                {category.value}
                            </li>
                        ))}
                    </ul>
                )}
                <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="28.5" cy="28.5" r="28.5" fill="#72BE44"/>
                    <path d="M27.5858 42.4142C28.3668 43.1953 29.6332 43.1953 30.4142 42.4142L43.1421 29.6863C43.9232 28.9052 43.9232 27.6389 43.1421 26.8579C42.3611 26.0768 41.0948 26.0768 40.3137 26.8579L29 38.1716L17.6863 26.8579C16.9052 26.0768 15.6389 26.0768 14.8579 26.8579C14.0768 27.6389 14.0768 28.9052 14.8579 29.6863L27.5858 42.4142ZM27 16L27 28.5L31 28.5L31 16L27 16ZM27 28.5L27 41L31 41L31 28.5L27 28.5Z" fill="#034828"/>
                </svg>
            </div>
            { view === 'calendar' &&       
                <ul className="keys">
                    {categories.map(category => {
                        const style = {
                            backgroundColor: category && category.color ? category.color : '#efefef'
                        };
                    
                        return (
                            <li key={category.value} value={category.value}>
                                <span className="color" style={style}></span>
                                <span className="">{category.value}</span>
                            </li>
                        )
                    })}
                </ul>
            }
        </div>
    );
};


const CalendarBlock = ({ block }) => {
    const attributes = block.attributes.data;
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [events, setEvents] = useState([]);

    useEffect(() => {
        // Fetch events from WordPress AJAX endpoint
        fetchEvents();
    }, []);

    const fetchEvents = () => {
        // Make AJAX request to WordPress AJAX endpoint
        fetch(`${apiBaseUrl}/wp-admin/admin-ajax.php?action=get_events_for_calendar_2`, {
            method: 'POST'
        })
        .then(response => response.json())
        .then(data => {
            // Update events state with fetched data
            // Filter out events with dates before today
            const today = new Date();
            today.setHours(0, 0, 0, 0); // Reset time to the start of today
            const filteredEvents = data.filter(event => {
                const eventDate = new Date(event.date);
                return eventDate >= today;
            });
            // Update events state with filtered data
            setEvents(filteredEvents);
        })
        .catch(error => {
            console.error('Error fetching events:', error);
        });
    };

    const handleMonthChange = (delta) => {
        const newMonth = currentMonth + delta;
        setCurrentMonth(newMonth >= 0 ? newMonth % 12 : 11);
        setCurrentYear(newMonth < 0 ? currentYear - 1 : newMonth > 11 ? currentYear + 1 : currentYear);
    };

    return (
        <div className="calendar-block">
            <Calendar 
                events={events}
                currentMonth={currentMonth}
                currentYear={currentYear}
                onMonthChange={handleMonthChange}
            />
        </div>
    );
}

export default CalendarBlock;



const Calendar = ({ events, currentMonth, currentYear, onMonthChange }) => {
    const [selectedCategories, setSelectedCategories] = useState(["All"]);
    const [expandedEvent, setExpandedEvent] = useState(null);
    const [view, setView] = useState('calendar');
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const handleResize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
        });
        if(window.innerWidth < 768){
            setView('list')
        }
    };

    const changeView = (view) => {
        setView(view);
    }

    useEffect(() => {
        handleResize();  
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        // Logic that depends on currentMonth and currentYear
    }, [currentMonth, currentYear]);

    // Collect unique categories from events
    const uniqueCategoriesSet = new Set(events.map(event => event.category));
    const uniqueCategories = [...uniqueCategoriesSet].map(category => {
        const event = events.find(event => event.category === category);
        return { value: category, color: event.color };
    });

    const handleCategoryChange = (newSelectedCategories) => {
        if (newSelectedCategories.includes("All") || newSelectedCategories.length === 0) {
          setSelectedCategories(["All"]);
        } else {
          // Remove "All" if other categories are selected
          const filteredCategories = newSelectedCategories.filter(category => category !== "All");
          setSelectedCategories(filteredCategories);
        }
      };
    
      const filteredEvents = events.filter(event => {
        // Check if the event category is included in selectedCategories
        const isCategoryIncluded = selectedCategories.includes("All") || selectedCategories.includes(event.category);
    
        // Check if any of the included categories match selectedCategories
        const isIncludedCategoriesIncluded = event.include_categories.some(includedCategory => {
            return selectedCategories.includes("All") || selectedCategories.includes(includedCategory);
        });
    
        // Return true if either the event category or included categories match selectedCategories
        return isCategoryIncluded || isIncludedCategoriesIncluded;
    });
    

    const handleEventClick = (event) => {
        setExpandedEvent(event);
    };

    const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];


    // Get the first and last date of the current month
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
    const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);
  
    const firstDayOfWeek = firstDayOfMonth.getDay();

    // Generate an array of dates for the current month
    const dates = [];
    let currentDate = firstDayOfMonth;
    while (currentDate <= lastDayOfMonth) {
        dates.push(new Date(currentDate.getTime()));  // Cloning the date
        currentDate.setDate(currentDate.getDate() + 1);
    }
    
    // Pad the start and end of the month with empty dates
    const paddingDays = Array(firstDayOfWeek).fill(null);
    dates.unshift(...paddingDays);
    const lastDayOfWeek = dates[dates.length - 1].getDay();
    const remainingDays = Array(6 - lastDayOfWeek).fill(null);
    dates.push(...remainingDays);
    const viewClassName = `calendar-container ${view === 'list' ? 'calendar-list' : 'full-calendar'}`;

    // Filter events for the current month
    const eventsForCurrentMonth = events.filter(event => {
        const eventDate = new Date(event.date);
        return eventDate.getMonth() === currentMonth && eventDate.getFullYear() === currentYear;
    });

    // Check if there are no events for the current month
    const hasNoEvents = eventsForCurrentMonth.length === 0;
    

  return (
    <div>
        <div className="calendar-title">Calendar</div>
        <div className="calendar-header">
            <button onClick={() => onMonthChange(-1)} className="calendar-header__button">
                <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="28.5" cy="28.5" r="28.5" transform="matrix(-1 0 0 1 57 0)" fill="#72BE44"/>
                    <path d="M16.5858 30.4142C15.8047 29.6332 15.8047 28.3668 16.5858 27.5858L29.3137 14.8579C30.0948 14.0768 31.3611 14.0768 32.1421 14.8579C32.9232 15.6389 32.9232 16.9052 32.1421 17.6863L20.8284 29L32.1421 40.3137C32.9232 41.0948 32.9232 42.3611 32.1421 43.1421C31.3611 43.9232 30.0948 43.9232 29.3137 43.1421L16.5858 30.4142ZM57 31L18 31L18 27L57 27L57 31Z" fill="#034828"/>
                </svg>
            </button>
            <span class="calendar-header__title">{monthNames[currentMonth]} {currentYear}</span>
            <button onClick={() => onMonthChange(1)} className="calendar-header__button">
                <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="28.5" cy="28.5" r="28.5" fill="#72BE44"/>
                    <path d="M40.4142 30.4142C41.1953 29.6332 41.1953 28.3668 40.4142 27.5858L27.6863 14.8579C26.9052 14.0768 25.6389 14.0768 24.8579 14.8579C24.0768 15.6389 24.0768 16.9052 24.8579 17.6863L36.1716 29L24.8579 40.3137C24.0768 41.0948 24.0768 42.3611 24.8579 43.1421C25.6389 43.9232 26.9052 43.9232 27.6863 43.1421L40.4142 30.4142ZM-1.74846e-07 31L39 31L39 27L1.74846e-07 27L-1.74846e-07 31Z" fill="#034828"/>
                </svg>
            </button>
        </div>
        <div className="tooling">
            <CategoryFilter
                categories={uniqueCategories}
                selectedCategories={selectedCategories}
                onChange={handleCategoryChange}
                view={view}
            />
            <div className="tooling-right">
                <button className="calendar-view" onClick={() => changeView('calendar')}>
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="0.885742" width="11.6516" height="11.6516" fill="#82C342"/>
                        <rect y="14.3086" width="11.6516" height="11.6516" fill="#82C342"/>
                        <rect x="13.7422" y="0.885742" width="11.6516" height="11.6516" fill="#82C342"/>
                        <rect x="13.7422" y="14.3086" width="11.6516" height="11.6516" fill="#82C342"/>
                    </svg>
                    <span>Calendar View</span>
                </button>
                <button className="calendar-view" onClick={() => changeView('list')}>
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="5.72949" width="19.9906" height="3.83508" fill="#82C342"/>
                        <rect x="0.326172" width="2.85504" height="3.83508" fill="#82C342"/>
                        <rect x="5.72949" y="7.07959" width="19.9906" height="3.83508" fill="#82C342"/>
                        <rect x="0.326172" y="7.08008" width="2.85504" height="3.83508" fill="#82C342"/>
                        <rect x="5.72949" y="14.1597" width="19.9906" height="3.83508" fill="#82C342"/>
                        <rect x="0.326172" y="14.1597" width="2.85504" height="3.83508" fill="#82C342"/>
                        <rect x="5.72949" y="21.2393" width="19.9906" height="3.83508" fill="#82C342"/>
                        <rect x="0.326172" y="21.2393" width="2.85504" height="3.83508" fill="#82C342"/>
                    </svg>
                    <span>List View</span>
                </button>
            </div>
        </div>
        {hasNoEvents ? (
                <div>No events for this month.</div>
            ) : (
            <div className="calendar-container--outer">
                    <div className={viewClassName}>
                        {dates.map((date, index) => (
                            <CalendarDate
                                key={index}
                                date={date}
                                events={
                                    date
                                        ? filteredEvents.filter(
                                            event =>
                                            new Date(event.date).getDate() === date.getDate() &&
                                            new Date(event.date).getMonth() === date.getMonth() &&
                                            new Date(event.date).getFullYear() === date.getFullYear()
                                        )
                                    : []
                                }
                                view={view}
                                onEventClick={handleEventClick}
                            />
                        ))}
                    </div>
                    { view === "calendar" &&
                        <EventDetails event={expandedEvent} />
                    }
            </div>
        )}
    </div>
  );
};

const CalendarDate = ({ date, events, onEventClick, view }) => {
    const dateDetails = extractDateDetails(date);
    console.log("MOO")
    // Function to parse the start time of the first range in the `times` string
    const parseStartTime = (timesString) => {
        if (!timesString) return 0;
        console.log("MOO")
        const firstRange = timesString.split(', ')[0]; // Get the first time range
        const startTime = firstRange.split('–')[0]; // Get the start time

        // Convert time to minutes since midnight for easier comparison
        const [time, modifier] = startTime.match(/(\d{1,2}:\d{2})(AM|PM)/i).slice(1, 3);
        let [hours, minutes] = time.split(':').map(Number);

        if (modifier === 'PM' && hours !== 12) {
            hours += 12;
        } else if (modifier === 'AM' && hours === 12) {
            hours = 0;
        }

        return hours * 60 + minutes;
    };

    // Sort events by their start times
    const sortedEvents = events.sort((a, b) => {
        console.log(events)
        return parseStartTime(a.times) - parseStartTime(b.times);
    });

    // Add conditional class name based on the view
    const dayClassName = `day ${view === 'list' ? 'day-list' : 'day-calendar'}`;
    const emptyDay = `${events.length > 0 ? '' : 'empty'}`;

    return (
        <div className={dayClassName + " " +  emptyDay}>
            {dateDetails !== null && (
                <div className="day-inner">
                    {view === 'calendar' &&
                       <div className="day__number">{dateDetails.dayOfMonth}</div>
                    }
                    {view === 'calendar' &&
                        sortedEvents.map((event) => {
                            const style = {
                                backgroundColor: event && event.color ? event.color : '#efefef',
                            };
                            return (
                                <div
                                    key={event.id}
                                    onClick={() => onEventClick(event)}
                                    className="day__event"
                                    style={style}
                                    dangerouslySetInnerHTML={{__html: event.title}}
                                /> 
                            );
                        })
                    }
                    {view === 'list' &&
                        <div className='list-day'>
                            <div className="day__number">{dateDetails.month} {dateDetails.dayOfMonth}</div>
                            {sortedEvents.map((event) => {
                                return (
                                    <div className="list-day-event" key={event.id}>
                                        <div className="list-day-event__image">
                                            <img src={event.image} alt={event.title} style={{ width: '100%', height: 'auto' }} />
                                        </div>
                                        <div className="list-day-event-details">
                                            <h3 dangerouslySetInnerHTML={{__html: event.title}} />
                                            {event.tag && (
                                                <p className="list-day-event-details__tags"><strong>Tags:</strong> {event.tag.map(tag => tag.name).join(", ")}</p>
                                            )}
                                            <p className="list-day-event-details__details">{event.details}</p>
                                            <a href={event.link}>
                                                <span>View Event Details</span>
                                                <svg width="19" height="8" viewBox="0 0 19 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5V3.5ZM18.3536 4.35355C18.5488 4.15829 18.5488 3.84171 18.3536 3.64645L15.1716 0.464466C14.9763 0.269204 14.6597 0.269204 14.4645 0.464466C14.2692 0.659728 14.2692 0.976311 14.4645 1.17157L17.2929 4L14.4645 6.82843C14.2692 7.02369 14.2692 7.34027 14.4645 7.53553C14.6597 7.7308 14.9763 7.7308 15.1716 7.53553L18.3536 4.35355ZM1 4.5H18V3.5H1V4.5Z" fill="#0D7D3F"/>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    }
                </div>
            )}
        </div>
    );
};



const EventDetails = ({ event }) => {
    // let date = event.date
    let date;
    // Define the inline style object based on the event color availability
    const style = {
        backgroundColor: event && event.color ? event.color : '#efefef'
    };

    if(event !== null && event.date){
        date = formatDate(event.date);
    }

    return (
      <div className="event-details" style={style}>
        { event &&
          event.title &&
            <div className="inner">
                {/* Map through included categories and display their names */}
                <div className="event-details__category">
                    {event.include_categories && event.include_categories.map((category, index) => (
                        <React.Fragment key={index}>
                            <div>
                                {index > 0 && <span>& </span>}
                                {index <= 0 && <span></span>}
                                {category}
                            </div>
                        </React.Fragment>
                    ))}
                </div>
                <h3 className="event-details__title" dangerouslySetInnerHTML={{__html: event.title}} />
                <p className="event-details__date">
                    {date.month}<br/>{date.monthDay}
                </p>
                <p className="event-details__details">{event.details}</p>
                {event.tag && (
                <p><strong>Tags:</strong> {event.tag.map(tag => tag.name).join(", ")}</p>
                )}
                <img className="event-details__image" src={event.image} alt={event.title} style={{ width: '100%', height: 'auto' }} />
                <a href={event.link} className="event-details__cta">
                    <span>View Event Details</span>
                    <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="28.5" cy="28.5" r="28.5" fill="#380C3E"/>
                        <path d="M40.4142 30.4142C41.1953 29.6332 41.1953 28.3668 40.4142 27.5858L27.6863 14.8579C26.9052 14.0768 25.6389 14.0768 24.8579 14.8579C24.0768 15.6389 24.0768 16.9052 24.8579 17.6863L36.1716 29L24.8579 40.3137C24.0768 41.0948 24.0768 42.3611 24.8579 43.1421C25.6389 43.9232 26.9052 43.9232 27.6863 43.1421L40.4142 30.4142ZM0 31L39 31V27L0 27L0 31Z" fill="white"/>
                    </svg>
                </a>
            </div>
        }
        { !event &&
         <div className="inner">
            No event selected.
         </div>
        }
      </div>
    );
};