import React, { useState, useEffect } from 'react';
import axios from 'axios';
import removeUnderscoreKeys from '../utilities/RemoveDataUnderscoreDuplicates';
import TransformImagesData from '../utilities/TransformImagesData';
import ImageWithFadeIn from '../utilities/ImageWithFadeIn';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const FullImageCardGridBlock = ({ block }) => {
    const attributes = block.attributes.data;
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [cards, setCards] = useState([]);

    let gridWidth = '';
    
    useEffect(() => {
        if (attributes.full_image_card_grid_posts) {
            let params = new URLSearchParams({
                action: 'get_custom_posts_simple',
                ids: attributes.full_image_card_grid_posts.join(',')
            });

            axios.post(`${apiBaseUrl}/wp-admin/admin-ajax.php?${params.toString()}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                console.log(response.data);
                setPosts(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching events:', error);
                setError(error);
                setLoading(false);
            });
        } else {
            if (attributes) {
                const fetchedCards = fetchCardData(attributes);
                setCards(fetchedCards);
                setLoading(false);
            }
        }
    }, [block]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error loading events: {error.message}</div>;

    if (posts.length > 2 || cards.length > 2) {
        gridWidth = "card-grid-item one-third";
    } else {
        gridWidth = "card-grid-item one-half";
    }

    function resolveUrl(url) {
        const currentHost = window.location.host;

        if (currentHost === "localhost:3000") {
            return url.replace(`${apiBaseUrl}`, 'localhost:3000/');
        }

        return url;
    }

    return (
        <div className="card-grid-item-block">
            <div className="card-grid-item-block-inner">
                { attributes.full_image_card_grid_posts &&
                    posts.map((post, index) => (
                        <a href={resolveUrl(post.link)} key={index} className={gridWidth}>
                            <div className="card-grid-item__img">
                                <img src={post.image} alt="" />
                            </div>
                            { post.title &&
                                <h3 className="card-grid-item__title" dangerouslySetInnerHTML={{__html: post.title}} />
                            }
                        </a>
                    ))
                }
                {cards.map((card, index) => (
                    <a href={resolveUrl(card.link)} key={index} className={gridWidth}>
                        <div className="card-grid-item__img">
                            <ImageWithFadeIn src={card.image.url} alt={card.image.alt} />
                        </div>
                        { card.title &&
                            <h3 className="card-grid-item__title" dangerouslySetInnerHTML={{__html: card.title}}/>
                        }
                    </a>
                ))}
            </div>
        </div>
    );
}

export default FullImageCardGridBlock;

const fetchCardData = (data) => {
    const count = data.full_image_card_grid_manual_content;  // This should be a number
    if (!count || typeof count !== 'number') {
        return [];
    }

    const results = [];

    for (let i = 0; i < count; i++) {
        const imageKey = `full_image_card_grid_manual_content_${i}_image`;
        const titleKey = `full_image_card_grid_manual_content_${i}_title`;
        const linkKey = `full_image_card_grid_manual_content_${i}_link`;

        results.push({
            title: data[titleKey],
            link: data[linkKey],
            image: data[imageKey]
        });
    }

    return results;
};
