    import React, { useState, useEffect } from 'react';
    import axios from 'axios';
    import ImageWithFadeIn from './ImageWithFadeIn';
    import debounce from 'lodash.debounce';

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const cache = {};

    const fetchImages = async (idsArray) => {
        if (!idsArray || idsArray.length === 0) {
            return [];
        }

        const cacheKey = idsArray.join(',');

        if (cache[cacheKey]) {
            return cache[cacheKey];
        }

        const response = await axios.post(
            `${apiBaseUrl}wp-admin/admin-ajax.php?action=get_media_items2`,
            JSON.stringify({ ids: idsArray })
        );

        if (response.data.success) {
            cache[cacheKey] = response.data.data;
            return response.data.data;
        } else {
            throw new Error(response.data.data);
        }
    };

    const TransformImagesData = ({ data }) => {
        const [images, setImages] = useState([]);
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState(null);

        useEffect(() => {
            if (!data || data.length === 0) {
                setLoading(false);
                return;
            }

            const debouncedFetch = debounce(async () => {
                try {
                    const idsArray = Array.isArray(data) ? data.filter(Boolean) : [data].filter(Boolean); // Filter out empty IDs
                    if (idsArray.length === 0) {
                        setLoading(false);
                        setImages([]);
                        return;
                    }
                    const imagesData = await fetchImages(idsArray);
                    console.log("mooooo")
                    setImages(imagesData);
                } catch (error) {
                    setError(error.message);
                } finally {
                    setLoading(false);
                }
            }, 300); // 300ms debounce

            debouncedFetch();

            return () => {
                debouncedFetch.cancel();
            };
        }, [data]);

        if (loading) return <div className="media-items__image"><img style={{opacity: 0}}/></div>;
        if (error) return <p>Error: {error}</p>;
        if (images.length === 0) return <p>No data found</p>;

        return (
            <div className="media-items">
                {images.map((item) => (
                    <div key={item.mediaItemId} className="media-items__image">
                        <ImageWithFadeIn src={item.sourceUrl} alt={item.altText} />
                    </div>
                ))}
            </div>
        );
    };

    export default TransformImagesData;
