import React from 'react';
import removeUnderscoreKeys from '../utilities/RemoveDataUnderscoreDuplicates'
import TransformImagesData from '../utilities/TransformImagesData';
import ImageWithFadeIn from '../utilities/ImageWithFadeIn';

const FeaturedImageBlock = ({ block }) => {
    const attributes = block.attributes.data;
    
    return (
        <div className="featured-image-block">
            <ImageWithFadeIn src={attributes.featured_image_image.url} alt={attributes.featured_image_image.alt} />
        </div>
    );
}

export default FeaturedImageBlock;