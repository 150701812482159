import React from 'react';

const RenderButtons = (buttons) => {
    
    // Mutate the object to the buttons key if it's passed through as props.
    if(buttons.buttons){
        buttons = buttons.buttons
    }

    return buttons.map((button, index) => (
        <a key={index} href={button.url || '#'} target={button.target || '_self'} style={{ margin: '5px' }} className={button.style == "1" ? "btn secondary" : "btn"}>
            {button.title || `Download ${button.file}`}
        </a>
    ));
};

export default RenderButtons;