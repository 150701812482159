import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, Link, useLocation, useParams } from 'react-router-dom';
import Page from './Page';
import MenuItem from './utilities/MenuItem';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const GTMPageView = () => {
  const location = useLocation();

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pageview',
      page: location.pathname + location.search
    });
  }, [location]);

  return null;
};

const FBMETAPageView = () => {
  const location = useLocation();
  useEffect(() => {
    if (window.fbq) {
        window.fbq('track', 'PageView', {
            page_path: location.pathname + location.search
        });
    }
  }, [location]);
  return null;
}


function App() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(true);
  const menu = data ? data.data.headerMenu.nodes[0].menuItems.nodes : null;
  const footerMenu = data ? data.data.footerMenu.nodes[0].menuItems.nodes : null;
  const footerMenuSecondary = data ? data.data.footerMenuSecondary.nodes[0].menuItems.nodes : null;
  const globalSettings = data ? data.data.globalSettings.globals.settings : null;
  const themeOptionsTopLevel = data ? data.data.globalSettings.globals.themeOptions : null;
  const currentYear = new Date().getFullYear();
  let dynamicApplicationClass = '';

  useEffect(() => {
    document.body.addEventListener('click', function(event) {
      // Check if the clicked element is an anchor tag
      const anchor = event.target.closest('a');
      if (anchor) {
        // Get the host name of the current URL
        const currentHostName = window.location.hostname;
        // Get the host name from the anchor tag's href attribute
        const anchorHref = anchor.href;
        const anchorHostName = new URL(anchorHref).hostname;

        const updatedHref = anchorHref.replace('localhost:8888', 'localhost:3000');

        // Compare the host names
        if (currentHostName !== anchorHostName) {
          event.preventDefault();
          window.open(anchorHref, '_blank');
        }

        // Update the href attribute if it was modified
        if (updatedHref !== anchorHref) {
          anchor.href = updatedHref;
        }
      }
    });
  }, []);

  useEffect(() => {
    const fetchGlobals = async () => {
      try {
        let params = new URLSearchParams({
          action: 'get_globals'
        });

        const response = await axios.post(`${apiBaseUrl}/wp-admin/admin-ajax.php?${params.toString()}`);

        if (response.data.success) {
          setData(response.data.data);
          setLoading(false)
          window.globalSettings = response.data.data;
        } else {
          console.error('Error:', response.data.data);
        }
      } catch (error) {
        console.error('Error fetching page:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchGlobals();
  }, []);

  const convertReturnBreakstoHTMLBreakTags = (address) => {
    return address.replace(/\r\n/g, '<br />');
  }

  const toggleMobileMenu = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const header = document.querySelector('header');
    const utility = document.querySelector('.utility');
    if (header.classList.contains('active')) {
      header.classList.remove('active');
      utility.classList.remove('active');
    } else {
      header.classList.add('active');
      utility.classList.add('active');
    }
  };

  const handleMenuItemClick = () => {
    // Add any additional actions you want to perform here
    const header = document.querySelector('header');
    const utility = document.querySelector('.utility');
    header.classList.remove('active');
    utility.classList.remove('active');
    setIsMobileMenuOpen(false); // Example action: close the mobile menu
  };

  if (themeOptionsTopLevel) {
    if (themeOptionsTopLevel === "primary") {
      dynamicApplicationClass = "_0"
    } else if (themeOptionsTopLevel === "secondary") {
      dynamicApplicationClass = "_1"
    } else if (themeOptionsTopLevel === "tertiary") {
      dynamicApplicationClass = "_2"
    }
  }

  // Wrapper component to use useParams
  const PageWrapper = (props) => {
    const { uri } = useParams();
    return <Page {...props} uri={uri} />;
  };

  if (loading) return <div className="loading"><div className="loading-inner"><div className="loader"></div></div></div>;
  return (
    <div>
      { data &&
        data.data &&
        globalSettings &&
          <div className={"application" + dynamicApplicationClass}>
              
            { globalSettings.themeOptions.map((icon, index) => {
                return (
                  <style key={`font_${index}`}>
                    {`.application_${index} {
                      --button-color: ${icon.button};
                      --section-upcoming-background: ${icon.upcomingContentContainerBackground};
                      --section-primary-background: ${icon.sectionPrimaryBackground};
                      --section-primary-text: ${icon.sectionPrimaryText};
                      --section-secondary-background: ${icon.sectionSecondaryBackground};
                      --section-secondary-text: ${icon.sectionSecondaryText};
                      --section-tertiary-background: ${icon.sectionTertiaryBackground};
                      --section-tertiary-text: ${icon.sectionTertiaryText};
                      --section-quarternary-background: ${icon.sectionQuaternaryBackground};
                      --section-quarternary-text: ${icon.sectionQuarternaryText};
                      --hero-two-column-color: ${icon.heroTwoColumn};
                      --hero-one-column-color: ${icon.heroOneColumn};
                      --hero-text-color: ${icon.heroTextColor};
                      --primary-color: ${icon.primary};
                      --secondary-color: ${icon.secondary};
                      --tertiary-color: ${icon.tertiary};
                      --quarternary-color: ${icon.quaternary};
                      --quinary-color: ${icon.quinary};
                      --senary-color: ${icon.senary};
                      --septenary-color: ${icon.septenary};
                      --octonary-color: ${icon.octonary};
                      --nonary-color: ${icon.nonary};
                    }`}
                  </style>
                );
              })
            }
            <Router>
              <FBMETAPageView />
              <GTMPageView />
              {data.data &&
                data.data.headerMenu &&
                <header className={isMobileMenuOpen ? 'active' : ''}>
                  <div className="header-outer">
                    <div className="header-inner">
                      <div className="header-logo-container">
                        <Link to={"/"}>
                          {globalSettings &&
                            globalSettings.logo.node.sourceUrl &&
                            <img src={globalSettings.logo.node.sourceUrl} />
                          }
                        </Link>
                      </div>
                      <div className="hamburger-icon" onClick={(event) => toggleMobileMenu(event)}>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                      </div>
                      <div className={`utility ${isMobileMenuOpen ? 'active' : ''}`}>
                        <div className="menu-items">
                          {menu &&
                            menu.length > 0 &&
                            menu.map((menuItem, index) => {
                              return <MenuItem data={menuItem} key={index} onMenuItemClick={handleMenuItemClick} />
                            })
                          }
                        </div>
                        {globalSettings &&
                          globalSettings.headerMainCta &&
                          <a href={globalSettings.headerMainCta.url} className="btn">{globalSettings.headerMainCta.title}</a>
                        }
                      </div>
                      <a href="/search" className="utility__icon">
                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M20.5311 18.0569H19.2336L18.7738 17.6137C20.7447 15.3157 21.7629 12.1806 21.2045 8.84842C20.4326 4.2852 16.6222 0.641191 12.0235 0.0830992C5.07607 -0.770453 -0.770905 5.0731 0.0831479 12.0164C0.641567 16.6125 4.28772 20.4206 8.85361 21.1921C12.1877 21.7502 15.3247 20.7325 17.6241 18.7628L18.0675 19.2224V20.5191L25.0478 27.4953C25.7212 28.1682 26.8216 28.1682 27.495 27.4953C28.1683 26.8223 28.1683 25.7225 27.495 25.0495L20.5311 18.0569ZM10.6767 18.0569C6.58709 18.0569 3.28585 14.7576 3.28585 10.6704C3.28585 6.58323 6.58709 3.28392 10.6767 3.28392C14.7663 3.28392 18.0675 6.58323 18.0675 10.6704C18.0675 14.7576 14.7663 18.0569 10.6767 18.0569Z" fill="#009448" />
                        </svg>
                      </a>
                    </div>
                  </div>
                </header>
              }
              <Routes>
                <Route path="/" element={<Page uri="" />} />
                <Route path="/:uri" element={<PageWrapper />} />
                <Route path="/events/:uri" element={<PageWrapper />} />
              </Routes>
              {data.data &&
                data.data.footerMenuSecondary &&
                data.data.footerMenu &&
                <footer>
                  <div className="inner">
                    <div className="grid-wrapper">

                      <div className="grid lap-one-third text-left">
                        <ul className="sub-pages">
                          {footerMenu &&
                            footerMenu.length > 0 &&
                            footerMenu.map((menuItem, index) => {
                              return <MenuItem data={menuItem} key={index} onMenuItemClick={handleMenuItemClick} />
                            })
                          }
                        </ul>
                        <div className="link-wrapper">
                          {globalSettings &&
                            globalSettings.addressUrl &&
                            <a href={globalSettings.addressUrl}>
                              {globalSettings &&
                                globalSettings.address &&
                                <span dangerouslySetInnerHTML={{ __html: convertReturnBreakstoHTMLBreakTags(globalSettings.address) }} />
                              }
                            </a>
                          }
                        </div>
                        <div className="link-wrapper">
                          {globalSettings &&
                            globalSettings.emailAddress
                            &&
                            <a href={"mailto:" + globalSettings.emailAddress}>
                              {globalSettings.emailAddress}
                            </a>
                          }
                        </div>
                      </div>
                      <div className="grid lap-one-third logo">
                        <Link to={"/"}>
                          {globalSettings &&
                            globalSettings.footer_logo.node.sourceUrl &&
                            <img src={globalSettings.footer_logo.node.sourceUrl} />
                          }
                        </Link>
                        <div className="social-links">
                          {globalSettings &&
                            globalSettings.social_icons.map((icon, index) => {
                              return <a href={icon.url} target="_blank" dangerouslySetInnerHTML={{ __html: icon.svg }} key={index} />
                            })
                          }
                        </div>
                      </div>
                      {globalSettings &&
                        globalSettings.partners &&
                        globalSettings.partners.length > 0 &&
                        <div className="grid lap-one-third text-right">
                          <div className='footer_logos'>
                            {
                              globalSettings.partners.map((partner, index) => {
                                let logoImage = partner.partner.edges[0].node.partnerSettings.ps.partnerLogo.node.sourceUrl;
                                return (
                                  <img src={logoImage} style={{ "maxWidth": partner.width + "px" }} key={index} />
                                )
                              })
                            }
                          </div>
                        </div>
                      }
                    </div>
                  </div>

                  <div className="footer-legal-bar ">
                    <span>Copyright {currentYear}</span>
                    <ul className="legal-pages">
                      {footerMenuSecondary &&
                        footerMenuSecondary.length > 0 &&
                        footerMenuSecondary.map((menuItem, index) => {
                          return <MenuItem data={menuItem} key={index} />
                        })
                      }
                    </ul>
                  </div>
                </footer>
              }
            </Router>
          </div>
        }
      </div>
  );
}

export default App;
