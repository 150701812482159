import React, { useState, useEffect } from 'react';
import axios from 'axios';
import removeUnderscoreKeys from '../utilities/RemoveDataUnderscoreDuplicates';
import ImageWithFadeIn from '../utilities/ImageWithFadeIn';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const PostGridBlock = ({ block }) => {
    const attributes = block.attributes.data;
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    
    useEffect(() => {
        let params = new URLSearchParams({
            action: 'get_custom_posts',
            type: attributes.post_grid_post_type,
            posts_per_page: attributes.post_grid_max_count
        });

        if (attributes.post_grid_type === "manual") {
            params.append('query', 'manual');
            params.set('posts_per_page', attributes.post_grid_manual_posts.length);
            params.append('ids', attributes.post_grid_manual_posts.join(','));
        } else {
            params.append('query', 'auto');
        }

        axios.post(`${apiBaseUrl}/wp-admin/admin-ajax.php?${params.toString()}`)
        .then(response => {
            setPosts(response.data); 
            setLoading(false);
        })
        .catch(error => {
            console.error('Error fetching events:', error);
            setError(error);
            setLoading(false);
        });
    }, [block]); // Depend on `block` if you expect it to change, or remove dependency array for static data

    if (loading) return <div className="post-grid-block-inner" style={{minHeight: "400px"}}></div>;
    if (error) return <div>Error loading events: {error.message}</div>;

    // Determine the class based on the number of posts
    const postGridClass = posts.length < 4 ? `post-grid-block grid-is-${posts.length}` : 'post-grid-block';

    return (
        <div className={postGridClass}>
            <div className="post-grid-block-inner" style={{minHeight: "400px"}}>
                {posts.map((post, index) => (
                    post.link ? (
                        <a key={index} href={post.link} className={attributes.post_grid_disable_hover ? "no-hover post-grid-card" : "post-grid-card"}>
                            <div className="post-grid-card-inner">
                                <div className="post-grid-card__img">
                                    <img src={post.image} alt={post.title} />
                                </div>
                                <div className="post-grid-card-content">
                                    {post.type === "events" && (
                                        <div>
                                            <p>
                                                {post.location}<br/>
                                                {post.date} {post.times}
                                            </p>
                                        </div>
                                    )}
                                    {post.type === "amenities" && (
                                        <div>
                                            <p>{post.description}</p>
                                        </div>
                                    )}
                                    <div className="post-grid-card__link">
                                        <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="27.5" cy="27.5" r="27.5" fill="#72BE44"/>
                                            <path d="M39.0458 28.6247C39.8268 27.8436 39.8268 26.5773 39.0458 25.7962L26.3179 13.0683C25.5368 12.2873 24.2705 12.2873 23.4894 13.0683C22.7084 13.8494 22.7084 15.1157 23.4894 15.8967L34.8032 27.2105L23.4894 38.5242C22.7084 39.3052 22.7084 40.5715 23.4894 41.3526C24.2705 42.1336 25.5368 42.1336 26.3179 41.3526L39.0458 28.6247ZM-1.74846e-07 29.2104L37.6316 29.2105L37.6316 25.2105L1.74846e-07 25.2104L-1.74846e-07 29.2104Z" fill="#034828"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            { !attributes.post_grid_hide_titles &&
                                <h3 className="post-grid-card__title" dangerouslySetInnerHTML={{ __html: post.title }} />
                            }
                        </a>
                    ) : (
                        <div key={index} className={attributes.post_grid_disable_hover ? "no-hover post-grid-card" : "post-grid-card"}>
                            <div className="post-grid-card-inner">
                                <div className="post-grid-card__img">
                                    <img src={post.image} alt={post.title} />
                                </div>
                                <div className="post-grid-card-content">
                                    {post.type === "events" && (
                                        <div>
                                            <p>
                                                {post.location}<br/>
                                                {post.date} {post.times}
                                            </p>
                                        </div>
                                    )}
                                    {post.type === "amenities" && (
                                        <div>
                                            <p>{post.description}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            { !attributes.post_grid_hide_titles &&
                                <h3 className="post-grid-card__title" dangerouslySetInnerHTML={{ __html: post.title }} />
                            }
                        </div>
                    )
                ))}
            </div>
        </div>
    );
}

export default PostGridBlock;
