import removeUnderscoreKeys from './RemoveDataUnderscoreDuplicates'

const TransformContentData = (data) => {
    let transformed = [];

    data = removeUnderscoreKeys(data);

    // Initialize base objects for each content index
    Object.keys(data).forEach(key => {
        let match = key.match(/^image_and_text_block_content_(\d+)_/);
        if (match) {
            const index = parseInt(match[1], 10);
            transformed[index] = transformed[index] || { type: null, value: null, buttons: [] };
        }
    });

    // Populate contents
    Object.keys(data).forEach(key => {
        let contentMatch = key.match(/^image_and_text_block_content_(\d+)_(.+)$/);
        if (contentMatch) {
            const index = parseInt(contentMatch[1], 10);
            const attribute = contentMatch[2];
            const value = data[key];

            // Handling SEO settings directly in the corresponding header
            if (attribute.includes('header_seo')) {
                if (transformed[index]) {
                    transformed[index]['seo'] = value;
                }
            } else if (attribute.startsWith('buttons') && !attribute.includes('buttons_')) {
                // Skip buttons initialization here
            } else {
                // Assign standard content fields
                if (transformed[index]) {
                    transformed[index]['type'] = attribute.replace(/_[0-9]+$/, '').replace(/_/g, ' ');
                    transformed[index]['value'] = value;
                }
            }
        }
    });

    // Handling buttons separately to control their initialization
    Object.keys(data).forEach(key => {
        let buttonMatch = key.match(/^image_and_text_block_content_(\d+)_buttons_(\d+)_(.+)$/);
        if (buttonMatch) {
            const contentIndex = parseInt(buttonMatch[1], 10);
            const buttonIndex = parseInt(buttonMatch[2], 10);
            const buttonProp = buttonMatch[3];
            let buttonValue = data[key];

            transformed[contentIndex].buttons[buttonIndex] = transformed[contentIndex].buttons[buttonIndex] || {};
            if (buttonProp === 'link' && typeof buttonValue === 'object') {
                transformed[contentIndex].buttons[buttonIndex] = {...transformed[contentIndex].buttons[buttonIndex], ...buttonValue, type: 'link'};
            } else {
                transformed[contentIndex].buttons[buttonIndex][buttonProp] = buttonValue;
            }
        }
    });

    // Filter out empty button arrays in each content item
    transformed.forEach(item => {
        if (item.buttons && item.buttons.length) {
            item.buttons = item.buttons.filter(button => button && Object.keys(button).length > 0);
        } else {
            delete item.buttons; // Remove buttons key if it's empty
        }
    });

    // Final clean up to ensure all entries are fully defined and have content
    let contentArray = transformed.filter(item => item && item.type);
    contentArray.forEach((key, index) => {
       if(contentArray[index].type.includes("large body content")){
        contentArray[index].type = "large_body_content"
       }else if(contentArray[index].type.includes("body content")){
        contentArray[index].type = "body_content"
       }else if(contentArray[index].type.includes("buttons")){
        contentArray[index].type = "buttons"
       }
    })
    return contentArray;
}

export default TransformContentData;