const removeUnderscoreKeys = (obj) => {
    // Check if obj is not an object or is null, then return it as is
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    let newObj = {};
    Object.keys(obj).forEach(key => {
        if (!key.startsWith('_')) {
            // Handle nested objects recursively
            if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
                newObj[key] = removeUnderscoreKeys(obj[key]);
            } else {
                newObj[key] = obj[key];
            }
        }
    });
    return newObj;
}

export default removeUnderscoreKeys;
