import React from 'react';
import removeUnderscoreKeys from '../utilities/RemoveDataUnderscoreDuplicates';
import TransformImagesData from '../utilities/TransformImagesData';
import ImageWithFadeIn from '../utilities/ImageWithFadeIn';

const SlimHeaderAndContentBlock = ({ block }) => {
    const attributes = block.attributes.data;
    
    const isContentEmpty = !attributes.slim_header_and_content_large_body_content && 
                           !attributes.slim_header_and_content_body_content && 
                           (!attributes.slim_header_and_content_link || !attributes.slim_header_and_content_link.title);

    // Extract data and render as needed
    return (
        <div className="slim-header-and-content-block">
            { attributes.slim_header_and_content_image_left &&
                <div className="slim-header-and-content-block-image-left">
                    <ImageWithFadeIn src={attributes.slim_header_and_content_image_left.url} alt={attributes.slim_header_and_content_image_left.alt} />

                </div>
            }
            { attributes.slim_header_and_content_image_right &&
                <div className="slim-header-and-content-block-image-right">
                    <ImageWithFadeIn src={attributes.slim_header_and_content_image_right.url} alt={attributes.slim_header_and_content_image_right.alt} />
                </div>
            }
            <div className={`slim-header-and-content-block-inner ${isContentEmpty ? 'empty-content' : ''}`}>
                <div className={`slim-header-and-content-block-heading`}>
                    {attributes.slim_header_and_content_heading && React.createElement(
                        attributes.slim_header_and_content_heading_seo || 'div',
                        { 
                            className: 'slim-header-and-content-block__header',
                            dangerouslySetInnerHTML: { __html: attributes.slim_header_and_content_heading }
                        }
                    )}
                </div>
                <div className="slim-header-and-content-block-settings">
                    
                    { attributes.slim_header_and_content_large_body_content && 
                        <div className="slim-header-and-content-block__body_large" dangerouslySetInnerHTML={{ __html: attributes.slim_header_and_content_large_body_content }} />
                    }

                    { attributes.slim_header_and_content_body_content && 
                        <div className="slim-header-and-content-block__body_small" dangerouslySetInnerHTML={{ __html: attributes.slim_header_and_content_body_content }} />
                    }
                    
                    { attributes.slim_header_and_content_link &&
                        <div className="buttons-wrapper">
                            <a className="btn" href={attributes.slim_header_and_content_link.url} style={{ margin: '5px' }}>
                                {attributes.slim_header_and_content_link.title}
                            </a>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default SlimHeaderAndContentBlock;
