import React, { useState, useEffect } from 'react';
import ImageWithFadeIn from '../utilities/ImageWithFadeIn';
import RenderButtons from '../utilities/RenderButtons';
import GravityForm from "react-gravity-form";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const TwoColumnActionBlock = ({ block }) => {
    const [loading, setLoading] = useState(true);
    const [blockSettings, setBlockSettings] = useState(null);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        source: window.location.hostname, // Set source to current domain name
        interest: '', // Initialize interest as empty
    });
    const attributes = block.attributes.data;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        const globalSettings = window.globalSettings;
        setBlockSettings(globalSettings.data.globalSettings.globals.settings.blocksettings)
        setLoading(false)
    }, []);

    useEffect(() => {
      const handleMutation = (mutationList) => {
          mutationList.forEach((mutation) => {
              if (mutation.type === 'childList') {
                  const sourceInput = document.querySelector('input[aria-label="Source"]');
                  if (sourceInput) {
                      // Update the field value
                      sourceInput.value = window.location.href;
                
                  }
              }
          });
      };
  
      const observer = new MutationObserver(handleMutation);
      observer.observe(document, { childList: true, subtree: true });
  
      return () => {
          observer.disconnect();
      };
  }, []);
  

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${apiBaseUrl}/wp-admin/admin-ajax.php?action=subscribe_to_newsletter`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const extractButtons = (data) => {
        const buttonCount = data.two_column_action_buttons;
        const buttons = [];
        for (let i = 0; i < buttonCount; i++) {
            const buttonKey = `two_column_action_buttons_${i}_button`;
            const buttonStyleKey = `two_column_action_buttons_${i}_secondary`;
            if (data[buttonKey]) {
                const style = data[buttonStyleKey];
                const { title, url, target } = data[buttonKey];
                buttons.push({ title, url, target, style });
            }
        }
        return buttons;
    }

    const baseClass = "two-column-action-block";
    const className = `${baseClass} ${attributes.two_column_action_neutral_style === "1" ? 'neutral' : ''}`;

    return (
        <div className={className}>
            <div className="two-column-action-block-inner">
                <div className="column">
                    <div className="content">
                        {blockSettings && blockSettings.twoColumnActionBlockInnerContainerImageLeft &&
                            <img src={blockSettings.twoColumnActionBlockInnerContainerImageLeft.node.sourceUrl} alt=" " className="anchor-image-top-left" />
                        }
                        {attributes.two_column_action_header && React.createElement(
                            attributes.two_column_action_header_seo || 'div',
                            {
                                className: 'two-column-action__header',
                                dangerouslySetInnerHTML: { __html: attributes.two_column_action_header }
                            }
                        )}
                        {attributes.two_column_action_body &&
                            <div
                                className="two-column-action__body"
                                dangerouslySetInnerHTML={{ __html: attributes.two_column_action_body }} />
                        }
                        {attributes.two_column_action_small_body &&
                            <div
                                className="two-column-action__small_body"
                                dangerouslySetInnerHTML={{ __html: attributes.two_column_action_small_body }} />
                        }
                        {attributes.two_column_action_buttons &&
                            <div className="buttons-wrapper">
                                <RenderButtons buttons={extractButtons(attributes)} />
                            </div>
                        }
                        {blockSettings && blockSettings.twoColumnActionBlockInnerContainerImageRight &&
                            <img src={blockSettings.twoColumnActionBlockInnerContainerImageRight} alt=" " className="anchor-image-bottom-right" />
                        }
                    </div>
                </div>
                <div className="column">
                    {attributes.two_column_action_option == "custom_form" &&
                        <div className="gravity-form">
                            <GravityForm
                                backendUrl={`${apiBaseUrl}/wp-json/glamrock/v1/gf/forms`}
                                formID={attributes.two_column_action_gravity_forms_id}
                            />
                        </div>
                    }
                    {attributes.two_column_action_option == "image" &&
                        <div className="media-items--outer">
                            <ImageWithFadeIn src={attributes.two_column_action_image.url} alt={attributes.two_column_action_image.alt} />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default TwoColumnActionBlock;
