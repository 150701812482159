import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import RenderBlock from './utilities/RenderBlock';
import { Helmet } from 'react-helmet';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const Page = () => {
  const location = useLocation();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [data, setData]= useState(null);
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchPageData = async () => {
      try {
        let params = new URLSearchParams({
          action: 'get_page'
        });
        setLoading(true);
        const response = await axios.post(`${apiBaseUrl}/wp-admin/admin-ajax.php?${params.toString()}`, {
          uri: location.pathname
        });

        if (response.data.success) {
          setData(response.data.data);
          console.log(response.data.data)
        } else {
          console.error('Error:', response.data.data);
        }
      } catch (error) {
        console.error('Error fetching page:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchPageData();
  }, [location.pathname]);

  const seo = data?.data.pageBy?.seo || {};
  const blocks = data?.data.pageBy?.blocks || [];

  const {
    metaDesc = '',
    metaRobotsNoindex = 'index',
    metaRobotsNofollow = 'follow',
    opengraphUrl = '',
    opengraphType = '',
    opengraphTitle = '',
    opengraphSiteName = '',
    opengraphImage = '',
    opengraphPublisher = '',
    opengraphPublishedTime = '',
    opengraphModifiedTime = '',
    twitterDescription = '',
    twitterImage = {},
    twitterTitle = '',
    canonical = '',
    title = '',
    readingTime = '',
  } = seo;

  if (loading) return <div className="loading"><div className="loading-inner"><div className="loader"></div></div></div>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div>
        <div className="page-content">
          <Helmet>
            <title>{title}</title>
            {metaDesc && <meta name="description" content={metaDesc} />}
            <meta name="robots" content={`${metaRobotsNoindex},${metaRobotsNofollow}`} />
            {opengraphUrl && <meta property="og:url" content={opengraphUrl} />}
            {opengraphType && <meta property="og:type" content={opengraphType} />}
            {opengraphImage && opengraphImage.sourceUrl && <meta property="og:image" content={opengraphImage.sourceUrl} />}
            {opengraphTitle && <meta property="og:title" content={opengraphTitle} />}
            {opengraphSiteName && <meta property="og:site_name" content={opengraphSiteName} />}
            {opengraphPublisher && <meta property="article:publisher" content={opengraphPublisher} />}
            {opengraphPublishedTime && <meta property="article:published_time" content={opengraphPublishedTime} />}
            {opengraphModifiedTime && <meta property="article:modified_time" content={opengraphModifiedTime} />}
            {twitterDescription && <meta name="twitter:description" content={twitterDescription} />}
            {twitterImage && twitterImage.sourceUrl && <meta name="twitter:image" content={twitterImage.sourceUrl} />}
            {twitterTitle && <meta name="twitter:title" content={twitterTitle} />}
            {canonical && <link rel="canonical" href={canonical} />}
            {readingTime && <meta name="readingTime" content={readingTime} />}
          </Helmet>
          {!loading && blocks.map((section, index) => {
            let color = '';
            if (section.attributes) {
              const attributes = section.attributes.data;
              if (attributes) {
                color = attributes.section_theme_color_option;
              }
            }
            return (
              <div key={index} className={"section " + color}>
                {section.innerBlocks.map((block, idx) => {
                  return <RenderBlock key={idx} block={block} />;
                })}
              </div>
            );
          })}
        </div>
    </div>
  );
};

export default Page;
