import React, { useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import removeUnderscoreKeys from '../utilities/RemoveDataUnderscoreDuplicates'
import axios from 'axios';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const FeaturedEventsBlock = ({ block }) => {
    const attributes = block.attributes.data;
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    
    useEffect(() => {
      if(attributes.featured_events_posts){
        let params = new URLSearchParams({
          action: 'get_featured_events',
          ids: attributes.featured_events_posts.join(',')
        });

        axios.post(`${apiBaseUrl}/wp-admin/admin-ajax.php?${params.toString()}`, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(response => {
          console.log("RESPONSE")
          console.log(response.data)
          setPosts(response.data); 
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching events:', error);
          setError(error);
          setLoading(false);
        });
      }
    }, [block]); // Depend on `block` if you expect it to change, or remove dependency array for static data

    function resolveUrl(url) {
      const currentHost = window.location.host;

      if (currentHost === "localhost:3000") {
          return url.replace(`${apiBaseUrl}`, 'http://localhost:3000/');
      }
  
      return url;
    }

    // Extract data and render as needed
    return (
        <div className="featured-events-block">
          <div className="inner">
            {attributes.featured_events_heading && React.createElement(
              attributes.featured_events_heading_seo || 'div',
              { 
                className: 'featured-events-block__header',
                dangerouslySetInnerHTML: { __html: attributes.featured_events_heading }
              }
            )}
            <div className="grid-wrapper">
              { attributes.featured_events_posts &&
                posts.map((post, index) => (
                  <div className="grid lap-one-half">
                    <a href={resolveUrl(post.link)} key={index} className="featured-event-item">
                      {post.image &&
                        <div className="featured-event-item__img">
                          <img src={post.image} />
                        </div>
                      }
                      <div className="featured-event-item-content">
                        <h3 className="featured-event-item__title" dangerouslySetInnerHTML={{__html: post.title}}/>
                        <div className="featured-event-item__body" dangerouslySetInnerHTML={{__html: post.excerpt}}/>
                        <span className="event-detail-action-secondary">
                          See event details
                          <span>
                            <svg width="19" height="8" viewBox="0 0 19 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5V3.5ZM18.3536 4.35355C18.5488 4.15829 18.5488 3.84171 18.3536 3.64645L15.1716 0.464466C14.9763 0.269204 14.6597 0.269204 14.4645 0.464466C14.2692 0.659728 14.2692 0.976311 14.4645 1.17157L17.2929 4L14.4645 6.82843C14.2692 7.02369 14.2692 7.34027 14.4645 7.53553C14.6597 7.7308 14.9763 7.7308 15.1716 7.53553L18.3536 4.35355ZM1 4.5H18V3.5H1V4.5Z" fill="#0D7D3F"/>
                            </svg>
                          </span>
                        </span>
                        <div className="svg-wrapper">
                          <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="27.5" cy="27.5" r="27.5" fill="#72BE44"/>
                            <path d="M39.0458 28.6247C39.8268 27.8436 39.8268 26.5773 39.0458 25.7962L26.3179 13.0683C25.5368 12.2873 24.2705 12.2873 23.4894 13.0683C22.7084 13.8494 22.7084 15.1157 23.4894 15.8967L34.8032 27.2105L23.4894 38.5242C22.7084 39.3052 22.7084 40.5715 23.4894 41.3526C24.2705 42.1336 25.5368 42.1336 26.3179 41.3526L39.0458 28.6247ZM-1.74846e-07 29.2104L37.6316 29.2105L37.6316 25.2105L1.74846e-07 25.2104L-1.74846e-07 29.2104Z" fill="#034828"/>
                          </svg>
                        </div>
                      </div>
                    </a>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
    );
}

export default FeaturedEventsBlock;