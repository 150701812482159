import React from 'react';
import RenderBody from './RenderBody';
import RenderButtons from './RenderButtons';
import RenderHeader from './RenderHeader';

const RenderRichContent = ({ block }) => {
    // Helper function to render buttons if available

    switch (block.type) {
        case 'header':
            return RenderHeader(block.value, block.seo);
        case 'large_body_content':
            return RenderBody('large', block.value);
        case 'body_content':
            return RenderBody('small', block.value);
        case 'buttons':
            return(
                <div className="buttons-wrapper">
                    {RenderButtons(block.buttons)}
                </div>
            );
        default:
            return <div>Unsupported content type</div>;
    }
};

export default RenderRichContent;