
import React, { useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import removeUnderscoreKeys from '../utilities/RemoveDataUnderscoreDuplicates';
import TransformImagesData from '../utilities/TransformImagesData';
import GetSlidesForSlider from '../utilities/GetSlidesForSlider';
import GetEventACFSettings from '../utilities/GetEventACFSettings';
import GetPartnerImageMedia from '../utilities/GetPartnerImageMedia';
import ImageWithFadeIn from '../utilities/ImageWithFadeIn';
import axios from 'axios';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;


const PostContentBlock = ({ block }) => {
  const [content, setContent] = useState([]); // Define content state

  const attributes = block.attributes.data;
  const contentBaseKey = 'post_content_content';

function getLastPartOfUrl(url) {
  // Parse the URL to ignore query parameters
  const parsedUrl = new URL(url);
  const urlWithoutQuery = parsedUrl.origin + parsedUrl.pathname;

  // Handle trailing slash
  const urlWithoutTrailingSlash = urlWithoutQuery.endsWith('/') ? urlWithoutQuery.slice(0, -1) : urlWithoutQuery;
  const parts = urlWithoutTrailingSlash.split('/');

  return parts.pop(); // Return the last segment
}

  const shareUrl = encodeURIComponent(window.location.href);
  const pageTitle = encodeURIComponent(document.title);

  const shareOnFacebook = () => {
      const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`;
      window.open(facebookUrl, '_blank');
  };

  const shareOnTwitter = () => {
      const twitterUrl = `https://twitter.com/intent/tweet?url=${shareUrl}&text=${pageTitle}`;
      window.open(twitterUrl, '_blank');
  };

  const copyToClipboard = () => {
      navigator.clipboard.writeText(window.location.href)
          .then(() => alert('URL copied to clipboard!'))
          .catch(err => alert('Failed to copy URL: ', err));
  };

    async function parseContent(data) {
        const contentItems = data.post_content_content;
        const parsedContent = [];
        for (let index = 0; index < contentItems.length; index++) {
          const type = contentItems[index];
          switch (type) {
            case 'header':
              parsedContent.push({
                type: 'header',
                text: data[`post_content_content_${index}_text`],
                seo: data[`post_content_content_${index}_seo`]
              });
              break;
            case 'body':
              parsedContent.push({
                type: 'body',
                size: data[`post_content_content_${index}_size`],
                content: data[`post_content_content_${index}_content`]
              });
              break;
            case 'images':
              parsedContent.push({
                type: 'images',
                images: data[`post_content_content_${index}_images`]
              });
              break;
            case 'buttons':
              const buttonCount = data[`post_content_content_${index}_buttons`];
              const buttons = [];
              for (let i = 0; i < buttonCount; i++) {
                buttons.push(data[`post_content_content_${index}_buttons_${i}_link`]);
              }
              parsedContent.push({
                type: 'buttons',
                buttons
              });
              break;
            case 'accordion':
              const accordionCount = data[`post_content_content_${index}_accordion`];
              const accordions = [];
              for (let i = 0; i < accordionCount; i++) {
                accordions.push({
                  id: `accordion-${index}-${i}`,
                  title: data[`post_content_content_${index}_accordion_${i}_accordion_title`],
                  content: data[`post_content_content_${index}_accordion_${i}_accordion_content`]
                });
              }
              parsedContent.push({
                type: 'accordion',
                items: accordions
              });
              break;
            case 'faqs':
              const faqs = data[`post_content_content_${index}_faq`];
              const params = new URLSearchParams({
                action: 'get_custom_get_faqs',
                ids: faqs.join(',')
              });
              const response = await axios.post(`${apiBaseUrl}/wp-admin/admin-ajax.php?${params.toString()}`, {
                headers: {
                  'Content-Type': 'application/json'
                }
              });
              const faqsAccordion = [];
              for (let i = 0; i < response.data.length; i++) {
                faqsAccordion .push({
                  id: `faqs-${index}-${i}`,
                  title: response.data[i].header,
                  content: response.data[i].body
                });
              }
              parsedContent.push({
                type: 'faqs',
                items: faqsAccordion 
              });
              break;
            default:
              break;
          }
        }
        return parsedContent;
      }
    useEffect(() => {
    parseContent(attributes).then(content => {
      setContent(content);
    });
  }, []); // Empty dependency array to run the effect only once when the component mounts

  return (
    <div className="post-content-block">
      <div className="post-content-inner">
        {content.map((item, index) => {
          switch (item.type) {
            case 'header':
              const Tag = item.seo || 'h2';
              return <Tag key={index} className="post-content__header" dangerouslySetInnerHTML={{ __html: item.text }} />;
            case 'body':
              return <div key={index} className={"post-content__body" + " " + item.size} dangerouslySetInnerHTML={{ __html: item.content }} />;
            case 'images':
              return (
                item.images.length > 1 ? 
                  <div><GetSlidesForSlider data={item.images} show={1.05} /></div>
                  :
                  <div key={index}>
                    <ImageWithFadeIn src={item.images[0].url} alt={item.images[0].alt} />
                  </div>
              );
            case 'buttons':
              return (
                <div key={index} className="buttons-wrapper">
                  {item.buttons.map((button, idx) => (
                    <a key={idx} href={button.url} target={button.target || "_self"} className="btn">{button.title}</a>
                  ))}
                </div>
              );
            case 'hr':
              return (
                <hr key={index}/>
              );
            case 'accordion':
              return (
                <div key={index} className="accordion">
                  {item &&
                  item.items &&
                    item.items.map((accordion, idx) => (
                    <div key={accordion.id} className="accordion-item">
                      <button 
                        className="accordion-title"
                        aria-expanded="false"
                        aria-controls={accordion.id}
                        onClick={(e) => {
                          const isExpanded = e.currentTarget.getAttribute('aria-expanded') === 'true';
                          e.currentTarget.setAttribute('aria-expanded', !isExpanded);
                          document.getElementById(accordion.id).style.display = isExpanded ? 'none' : 'block';
                        }}
                      >
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="13.5" cy="13.5" r="12.75" stroke="#00953C" strokeWidth="1.5"/>
                          <path d="M7 13H21M13.5 20V6" stroke="#01462B" strokeWidth="2" strokeLinecap="round"/>
                        </svg>
                        <span>{accordion.title}</span>
                      </button>
                      <div id={accordion.id} className="accordion-content" style={{ display: 'none' }}>
                        <p dangerouslySetInnerHTML={{ __html: accordion.content }} />
                      </div>
                    </div>
                  ))}
                </div>
              );
            case 'faqs':
                return (
                    <div key={index} className="accordion">
                      { item &&
                          item.items.length > 0 &&
                            item.items.map((accordion, idx) => (
                            <div key={accordion.id} className="accordion-item">
                                <button 
                                    className="accordion-title"
                                    aria-expanded="false"
                                    aria-controls={accordion.id}
                                    onClick={(e) => {
                                    const isExpanded = e.currentTarget.getAttribute('aria-expanded') === 'true';
                                    e.currentTarget.setAttribute('aria-expanded', !isExpanded);
                                    document.getElementById(accordion.id).style.display = isExpanded ? 'none' : 'block';
                                    }}
                                >
                                    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="13.5" cy="13.5" r="12.75" stroke="#00953C" strokeWidth="1.5"/>
                                    <path d="M7 13H21M13.5 20V6" stroke="#01462B" strokeWidth="2" strokeLinecap="round"/>
                                    </svg>
                                    <span>{accordion.title}</span>
                                </button>
                                <div id={accordion.id} className="accordion-content" style={{ display: 'none' }} dangerouslySetInnerHTML={{ __html: accordion.content }}>
                                </div>
                            </div>
                        ))}
                    </div>
                  );
            default:
              return null;
          }
        })}
      </div>
      <div className="post-content-banner">
            <GetEventACFSettings slug={getLastPartOfUrl(window.location.href)} />
            <div className="social-sharing">
                <h4 className="social-sharing__header">Share</h4>
                <div className="icons">
                    <button onClick={copyToClipboard} className="clipboard"><svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 55 54" width="55" height="54" ><circle className="st0" cx="27.5" cy="27" r="26.8" style={{fill:"#142615"}} /><g><g><path style={{fill:"#72be44"}} d="M29.8,31.1c-1.8,0-3.4-0.7-4.6-1.9c-0.6-0.6-0.6-1.5,0-2.1c0.6-0.6,1.5-0.6,2.1,0c0.7,0.7,1.6,1.1,2.6,1.1
                        s1.9-0.4,2.6-1.1l3.9-3.9c1.4-1.4,1.4-3.8,0-5.2s-3.8-1.4-5.2,0l-1.7,1.7c-0.6,0.6-1.5,0.6-2.1,0s-0.6-1.5,0-2.1L29,16
                        c2.6-2.6,6.7-2.6,9.3,0c2.6,2.6,2.6,6.7,0,9.3l-3.9,3.9C33.2,30.4,31.5,31.1,29.8,31.1z"/></g><g><path style={{fill:"#72be44"}} d="M22.3,38.5c-1.8,0-3.4-0.7-4.6-1.9c-2.6-2.6-2.6-6.7,0-9.3l3.9-3.9c2.6-2.6,6.7-2.6,9.3,0
                        c0.6,0.6,0.6,1.5,0,2.1c-0.6,0.6-1.5,0.6-2.1,0c-1.4-1.4-3.8-1.4-5.2,0l-3.9,3.9c-1.4,1.4-1.4,3.8,0,5.2c0.7,0.7,1.6,1.1,2.6,1.1
                        s1.9-0.4,2.6-1.1l1.7-1.7c0.6-0.6,1.5-0.6,2.1,0c0.6,0.6,0.6,1.5,0,2.1L27,36.6C25.7,37.9,24.1,38.5,22.3,38.5z"/></g></g></svg>
                    </button>
                    <button onClick={shareOnFacebook} className="facebook">
                        <svg width="55" height="54" viewBox="0 0 55 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="27.6033" cy="26.8094" r="26.8094" fill="#142615"/>
                            <path d="M31.4505 19.0908H33.4578V15.5631C32.4859 15.4581 31.5094 15.4062 30.5323 15.4077C27.6281 15.4077 25.6421 17.2493 25.6421 20.6217V23.5282H22.3643V27.4775H25.6421V37.5948H29.5713V27.4775H32.8385L33.3297 23.5282H29.5713V21.01C29.5713 19.8452 29.8703 19.0908 31.4505 19.0908Z" fill="#72BE44"/>
                        </svg>
                    </button>
                    <button onClick={shareOnTwitter} className="twitter">
                        <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_2405_134)">
                        <circle cx="31.1902" cy="26.8094" r="26.8094" fill="#142615"/>
                        <path d="M18.6167 15.4077L28.1347 27.6452L18.5566 37.5948H20.7123L29.0979 28.8838L35.8732 37.5948H43.2089L33.1554 24.669L42.0706 15.4077H39.915L32.1923 23.4304L25.9525 15.4077H18.6167ZM21.7868 16.9346H25.1568L40.0384 36.0677H36.6683L21.7868 16.9346Z" fill="#72BE44"/>
                        </g>
                        <defs>
                        <filter id="filter0_d_2405_134" x="0.380859" y="0" width="61.6191" height="61.6187" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="4"/>
                        <feGaussianBlur stdDeviation="2"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2405_134"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2405_134" result="shape"/>
                        </filter>
                        </defs>
                        </svg>

                    </button>
                </div>
            </div>
            { attributes.post_content_partner_logos &&
                <div className="partner-logos-wrapper">
                <h4 className="partner-logos-wrapper__header">Partners</h4>
                <div className="partner-logos"><GetPartnerImageMedia ids={attributes.post_content_partner_logos}/></div>
                </div>
            }
        </div>
      </div>
    );
  }
  
  export default PostContentBlock;