import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import AccordionBlock from '../components/Accordion';
import HeroBlock from '../components/Hero';
import ImageAndTextBlock from '../components/ImageAndText';
import CalendarBlock from '../components/Calendar';
import FeaturedImageBlock from '../components/FeaturedImage';
import FeaturedEventsBlock from '../components/FeaturedEvents';
import FullImageCardGridBlock from '../components/FullImageCardGrid';
import MapBlock from '../components/Map';
import PostGridBlock from '../components/PostGrid';
import PostContentBlock from '../components/PostContent';
import SimpleTextContentBlock from '../components/SimpleTextContent';
import SimpleWysiwygBlock from '../components/SimpleWysiwyg';
import SlimHeaderAndContentBlock from '../components/SlimHeaderAndContent';
import TwoColumnActionBlock from '../components/TwoColumnAction';
import UpcomingEventsBlock from '../components/UpcomingEvents';
import SearchBlock from '../components/Search';
import removeUnderscoreKeys from '../utilities/RemoveDataUnderscoreDuplicates';

const RenderBlock = ({ block, idx }) => {
    const blockRef = useRef(null);

    useEffect(() => {
        const element = blockRef.current;

        const handleIntersection = (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const isMobile = window.matchMedia('(max-width: 767px)').matches;
                    const offset = isMobile ? 50 : 150;

                    gsap.fromTo(element,
                        { opacity: 0 },
                        {
                            opacity: 1,
                            duration: 1,
                            ease: 'power1.inOut',
                            scrollTrigger: {
                                trigger: element,
                                start: `top bottom+=${offset}`,
                                end: `top bottom+=${offset * 2}`,
                                scrub: true
                            }
                        }
                    );
                    observer.unobserve(element);
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.1 // Adjust this value to control when the animation triggers
        });

        observer.observe(element);

        return () => {
            observer.disconnect();
        };
    }, []);

    const getBlockComponent = () => {
        const blockTypeClass = block.name.toLowerCase().replace('acf', '').replace('block', '').replace('/', '')
        switch (blockTypeClass) {
            case 'accordion':
                return <AccordionBlock block={block} />;
            case 'hero':
                return <HeroBlock block={block} />;
            case 'image-and-text-':
                return <ImageAndTextBlock block={block} />;
            case 'calendar':
                return <CalendarBlock block={block} />;
            case 'featured-image':
                return <FeaturedImageBlock block={block} />;
            case 'featured-events':
                return <FeaturedEventsBlock block={block} />;
            case 'full-image-card-grid':
                return <FullImageCardGridBlock block={block} />;
            case 'map':
                return <MapBlock block={block} />;
            case 'post-grid':
                return <PostGridBlock block={block} />;
            case 'post-content':
                return <PostContentBlock block={block} />;
            case 'simple-text-content':
                return <SimpleTextContentBlock block={block} />;
            case 'simple-wysiwyg':
                return <SimpleWysiwygBlock block={block} />;
            case 'slim-header-and-content':
                return <SlimHeaderAndContentBlock block={block} />;
            case 'two-column-action':
                return <TwoColumnActionBlock block={block} />;
            case 'upcoming-events':
                return <UpcomingEventsBlock block={block} />;
            case 'search':
                return <SearchBlock block={block} />;
            default:
                return <p>Unsupported block type</p>;
        }
    };

    const blockTypeClass = block.name.toLowerCase().replace('acf', '').replace('block', '').replace('/', '');

    const attributes = block.attributes.data;
    let heroStyle = '';
    if(attributes !== null && attributes.hero_type == "two_column"){
        heroStyle = attributes.hero_type
    }else{
        heroStyle = ''
    }
    return (
        <div ref={blockRef} key={idx} className={`animated-block ${blockTypeClass} ${heroStyle}`}>
            {getBlockComponent()}
        </div>
    );
};

export default RenderBlock;
