import React from 'react';

const RenderBody = (size, value) => {
    // Default to h1 if no seo tag is provided
    if(size == "small"){
        return <div className="body-content" dangerouslySetInnerHTML={{__html: value}} />
    }else{
        return <div className="body-content large" dangerouslySetInnerHTML={{__html: value}} />
    }
};

export default RenderBody