import React, { useState, useEffect } from 'react';

const ImageWithFadeIn = ({ src, alt, width, height }) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        let isActive = true; // Flag to check mount status

        const loadImage = () => {
            const image = new Image();
            image.onload = () => {
                if (isActive) setLoaded(true);
            };
            image.onerror = (error) => {
                console.error('Failed to load image:', error);
                // Optionally handle the error, e.g., display a placeholder image
            };
            image.src = src;
        };

        loadImage();

        return () => {
            isActive = false; // Set flag to false on unmount
        };
    }, [src]); // Ensure effect runs only when src changes

    return (
        <img
            src={src}
            alt={alt || 'Image'}
            style={{
                opacity: loaded ? 1 : 0,
                transition: 'opacity 0.5s ease-in'
            }}
        />
    );
};

export default ImageWithFadeIn;
