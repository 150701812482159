import React, { useState } from 'react';

const Tabs = ({ data }) => {
    const [activeTab, setActiveTab] = useState(0);

    return (
        <div className="tab-outer">
            <div className="tabs">
                {data.map((option, index) => (
                    <button
                        key={index}
                        className={`tab-button ${index === activeTab ? 'active' : ''}`}
                        onClick={() => setActiveTab(index)}
                    >
                        <div className="option-icon" dangerouslySetInnerHTML={{ __html: option.icon}} />
                        <div className='option-title' dangerouslySetInnerHTML={{ __html: option.title}} />
                    </button>
                ))}
            </div>
            <div className="tab-content">
                <p dangerouslySetInnerHTML={{ __html: data[activeTab].content }} />
            </div>
        </div>
    );
};

export default Tabs;
