import React from 'react';
import removeUnderscoreKeys from '../utilities/RemoveDataUnderscoreDuplicates'

const SimpleTextContentBlock = ({ block }) => {
    const attributes = block.attributes.data;
    
    function extractTextColumns(data) {
        const columnCount = data.simple_text_content_columns;
        const columns = [];
    
        for (let i = 0; i < columnCount; i++) {
            const headerKey = `simple_text_content_columns_${i}_light_header`;
            const seoKey = `simple_text_content_columns_${i}_light_header_seo`;
            const contentKey = `simple_text_content_columns_${i}_content`;
    
            if (data[headerKey] && data[contentKey] && data[seoKey] ) {
                columns.push({
                    header: data[headerKey],
                    seo: data[seoKey],
                    content: data[contentKey]
                });
            }
        }
    
        return columns;
    }

    const columns = extractTextColumns(attributes);
    
    // Extract data and render as needed
    return (
        <div className="simple-text-content-block">
            <div className="inner">
                <div className="grid-wrapper">
                    {columns.map((column, index) => (
                        <div className="grid lap-one-half" key={index}>
                            <div className="content">
                                {column.header && React.createElement(
                                    column.seo || 'div',
                                    { 
                                        className: 'content__header',
                                        dangerouslySetInnerHTML: { __html: column.header }
                                    }
                                )}
                                <div className="content__body" dangerouslySetInnerHTML={{ __html: column.content }} />
                            </div>
                        </div>
                    ))}
                </div>
                {attributes.simple_text_content_disclaimer &&
                    <div className="disclaimer" dangerouslySetInnerHTML={{ __html: attributes.simple_text_content_disclaimer}} />
                }
            </div>
        </div>
    );
}

export default SimpleTextContentBlock;