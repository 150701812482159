import React, { useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';

const QUERY = gql`
query GetMediaItems($ids: [ID!]) {
    partners(where: {in: $ids}) {
      nodes {
        partnerSettings {
          ps {
            partnerLogo {
              node {
                sourceUrl
              }
            }
            partnerName
            partnerUrl
          }
        }
      }
    }
  }
`;

const GetPartnerImageMedia = ({ ids }) => {
    console.log("IDS")
    console.log(ids)
    const [loaded, setLoaded] = useState(false);
    // Use the useQuery hook directly with conditional rendering based on 'images'
    const { data: queryData, loading, error } = useQuery(QUERY, {
        variables: { ids: ids}
    });

    if (loading) return <div></div>;
    if (error) return <p>Error: {error.message}</p>;
    if (!queryData) return <p>No data found</p>;

    return (
      queryData.partners.nodes.map(item => (
        <div className="partner-logos__image" key={item}>
          <img src={item.partnerSettings.ps.partnerLogo.node.sourceUrl} />
        </div>
      ))
    );
};

export default GetPartnerImageMedia;
